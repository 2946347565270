.info_block.block_close{
	max-height: 42px;
	overflow: hidden;
}

.panel-info.info_block.block_close .glyphicon-plus{
      display: inline-block!important;
}

.panel-info.info_block.block_close .glyphicon-minus{
      display: none!important;
}

.panel-info .panel-heading.panel-heading-ordine{
	color: #fff;
	background-image: linear-gradient(to bottom, #49acde 0%, #146792 100%);
}

.lavorazione_block.locked{
    max-height: 120px;
    overflow: hidden;
    opacity: 0.2;
}

.lavorazione_block.locked button{
	display: none;
}

.badge-important{
      background-color: #b94a48!important;
}

.glyphicon-user-plus:before{
      content: "\f234";
}

.row_lav.new_row{
      display: none;
}

.only_print{
      display: none;
}


#dataTable tr .avviso,
#dataTable tr .avviso >*{
      margin: 0;
      padding: 0;
      text-align: center;
}


@media print{  /*Regola dedicata alla visualizzazione su carta*/
      body *{
            font-size: 11px;
      }
      .disabled,
      .no_print{
          display: none;
      }
      .only_print{
            display: block;
      }
      input {
         border: 0;
         width: initial;
      }
      button{
            display: none;
      }
  }

  .edit_offerta{
        float: right;
        padding: 8px;
  }